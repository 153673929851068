

import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class StoreSearchProducts extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
      //
    }


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const selected_products = this.selected_products ?? null ;

      
      let html = '';
      {
        const c0 = selected_products
        const fl1 = new CF2ForloopDrop(c0.length)
        for (const product of c0) {
          const forloop = fl1
          const default_variant = product.default_variant ?? "{}";
          const image = product.image ?? default_variant.image ?? "/editor/editor-demo-image-placeholder.svg";
          const name = product.name ?? default_variant.name;
          const price = product.price ?? default_variant.price;
          html += `<div class="elStoreSearchProductCard">`
          if (image) {
            html += `<a style="margin:0;height:100%;" href="${product.url}"><div data-page-element="Image/V2" class="elImageWrapper de-image-block id-Image/V2`
            if (true && !image && !null && false == false) {
              html += ` forceHide`
            }
            html += `" data-liquid-replace="item">`
            if (image || !!null) {
              html += `<img class="elImage" src="${image ?? null}"/>`
            } else if (false) {
              html += `<div class="image-placeholder" title="This element will render with content once configured."><span class="image-placeholder-header">[Replaced by Content]</span></div>`
            }
            html += `</div></a>`
          }
          html += `<div class="elStoreSearchProductCardInfo"><a href="${product.url}" class="elStoreSearchProductCardName">${name}</a><span class="elStoreSearchProductCardPrice">${price}</span></div></div>`
          forloop.next();
        }

      }

      this.element.innerHTML = html;

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

registerComponent('StoreSearchProducts', StoreSearchProducts)
window["StoreSearchProducts"] = StoreSearchProducts

